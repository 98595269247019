import { defineStore } from 'pinia';
import AccountResource from '@/modules/accounts/api/account.api';

import campaignStore from '@/modules/campaigns/store/campaigns.store';
import { useFinanceStore } from '@/modules/settings/store/finance.store';
import { useMediaLibraryStore } from '@/modules/medialibrary/store/mediaLibrary.store';

import { Account } from '@/modules/accounts/models/account.model';

interface State {
  accounts: Account[];
  loading: boolean;
  selectedAccount: Account | null;
}

export const useAccountsStore = defineStore('accountsStore', {
  state: (): State => {
    return {
      accounts: [],
      loading: false,
      selectedAccount: null,
    }
  },

  getters: {

  },

  actions: {
    setLoading(value: boolean): void {
      this.loading = value;
    },
    async fetchAccounts(): Promise<void> {
      this.setLoading(true);
      const accounts = await AccountResource.prototype.findAll();
      this.setLoading(false);
      this.accounts = accounts;
    },
    fetchAccountData(): void {
      const financeStore = useFinanceStore();
      const mediaLibraryStore = useMediaLibraryStore();

      mediaLibraryStore.fetchMediaLibraryResources();
    },
    selectAccount(account: Account): void {
      campaignStore.resetCampaigns();
      campaignStore.updatePageCount(1);
      localStorage.setItem('selectedAccountName', account.name);
      this.selectedAccount = account;
      this.fetchAccountData();
    },
    async determineSelectedAccount(): Promise<void> {
      if (!this.accounts.length && !this.selectedAccount) {
        await this.fetchAccounts();
        this.selectAccountByLocalStorageName(this.accounts);
      }
    },
    selectAccountByLocalStorageName(fetchedAccounts: Account[]): void {
      if (!fetchedAccounts.length) {
        return;
      }
      const storedAccountName = localStorage.getItem('selectedAccountName');
      const selectedAccount = fetchedAccounts.find((account: Account) => account.name === storedAccountName) || fetchedAccounts[0];
      this.selectAccount(selectedAccount);
    },
  },
});
