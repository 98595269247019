import _ from 'lodash';
import { AbstractResource } from '@/core/api/abstract.api';
import { getDateWithTimezone, formatDate } from '@/core/plugins/date';
import httpService from '@/core/plugins/httpService';
import { Money } from 'ts-money';

import toastNotificationStore from '@/core/store/toastNotification.store';
import { useAccountsStore } from '@/modules/accounts/store/accounts.store';
import { useWorkspaceStore } from '@/modules/accounts/store/workspaces.store';

import { AdMediaJson, hydrateAdMedia } from '@/modules/ads/api/apiHelpers';
import {
    ActivityLogItem,
    Campaign,
    CampaignStatus,
    CampaignType,
    ChannelsBudget,
    CpcAndCpmItem,
    CreateCampaignParams,
    DataToExtendCampaign,
    Leadform,
    LeadformQuestion,
    Statistics,
} from '@/modules/campaigns/models/campaign.model';
import { CampaignGoalValue } from '@/modules/campaigns/models/campaign.goal.model';
import { convertTypeForDatabase, convertTypeForFrontend } from '@/core/store/productConfig';
import { FacebookCapStrategy, FacebookJobListData } from '@/modules/JobLibrary/models/jobLibrary.model';
import { FunctionGroup } from '@/modules/campaigns/models/function.group.model';
import { getBudgetNum } from '@/modules/ads/helpers';
import { JobProfile } from '@/core/models/jobProfile.model';
import { Location } from '@/core/models/location.model';
import { SocialPage } from '@/modules/accounts/models/socialPage.model';

export default class CampaignResource extends AbstractResource<Campaign, CampaignResponseJson, CampaignRequestJson> {

    protected get resourceName() {
        return 'campaigns';
    }

    protected get resourceOwner() {
        const accountsStore = useAccountsStore();
        const workspaceStore = useWorkspaceStore();

        return 'accounts/' + accountsStore.selectedAccount!.id + '/workspaces/' + workspaceStore.selectedWorkspace!.slug;
    }

    public fetchCampaignsByWorskpace(workspaceSlug: string, pageCount: string, campaignsStatus?: CampaignStatus[]): Promise<Campaign[]> {
        const accountsStore = useAccountsStore();
        return httpService.get(`/accounts/${accountsStore.selectedAccount!.id}/${workspaceSlug}/${this.resourceName}?page=${pageCount}&perpage=10`, {
            params: {
                status: campaignsStatus?.join(','),
            },
        })
            .then((response) => this.hydrateCollection(response.data.data as CampaignResponseJson[]));
    }

    public fetchAllActivityLogs(campaignId: string): Promise<ActivityLogItem[]> {
        return httpService.get('/campaigns/' + campaignId + '/activity-log')
            .then((response) => response.data.data);
    }

    public async fetchFunctionGroups(): Promise<FunctionGroup[]> {
        const response = await httpService.get(`/enum/function-groups`);
        return response.data;
    }

    public fetchCPCAndCPMBasedOnFunctionGroup(campaignId: string): Promise<CpcAndCpmItem> {
        return httpService.get(`/campaigns/${campaignId}/cpm-cpc`)
        .then((response) => response.data);
    }

    public async duplicate(campaign: Campaign): Promise<string> {
        return httpService.post(this.entityEndpoint + '/' + this.identifier(campaign) + '/duplicate').then((response) => {
            return (response.data as DuplicateResponseJson).campaign_id;
        });
    }

    public createNewCampaign(params: CreateCampaignParams): Promise<Campaign> {
        const creationParams = {
            ...params,
            type: convertTypeForDatabase(params.type),
        };
        return httpService.post('/' + this.resourceName, creationParams).then((response) => {
            return this.hydrate(response.data as CampaignResponseJson);
        });
    }

    public updateCampaignFromTemplate(campaignId: string, templateId: string) {
        return httpService.put(this.entityEndpoint + '/' + campaignId + '/' + templateId + '/change-template').then((response) => {
            return this.hydrate(response.data as CampaignResponseJson);
        }).catch((err: any) => {
          const budget = getBudgetNum(err.response.data.message);
            if (budget) {
                toastNotificationStore.showToastNotification({
                    message: `Not enough budget. Should be at least ${budget / 100}`,
                    isError: true,
                });
            }
          return false;
      });
    }

    public updateCampaignFields(campaign: Campaign): Promise<void> {
        const fields = this.extractCampaignFields(campaign);
        return httpService.put(this.entityEndpoint + '/' + this.identifier(campaign), fields).then(() => { return; }).catch((error) => {
            if (error.response.data.errors.target_url) {
                toastNotificationStore.showToastNotification({
                    message: `The URL should include https:// and no spaces`,
                    isError: true,
                });
            }
        });
    }

    public updateSchedule(campaign: Campaign, startDate: string | Date, endDate: string | Date): Promise<void> {
        const url = this.entityEndpoint + '/' + this.identifier(campaign) + '/schedule';
        return httpService.put(url, {
            start_date: formatDate(startDate),
            end_date: formatDate(endDate),
        }).then(() => { return; });
    }

    public resume(campaign: Campaign): Promise<void> {
        return httpService.post(this.entityEndpoint + '/' + this.identifier(campaign) + '/resume');
    }
  
    public resumeDynamicCampaign(campaign: Campaign): Promise<void> {
        const accountsStore = useAccountsStore();
        return httpService.put(`/dynamic-ads/${accountsStore.selectedAccount!.id}/campaigns/${this.identifier(campaign)}/resume`);
    }    

    public stop(campaign: Campaign): Promise<void> {
        return httpService.post(this.entityEndpoint + '/' + this.identifier(campaign) + '/stop');
    }
  
    public stopDynamicCampaign(campaign: Campaign): Promise<void> {
        const accountsStore = useAccountsStore();
        return httpService.put(`/dynamic-ads/${accountsStore.selectedAccount!.id}/campaigns/${this.identifier(campaign)}/stop`);
    }  

    public pause(campaign: Campaign): Promise<void> {
        return httpService.post(this.entityEndpoint + '/' + this.identifier(campaign) + '/pause');
    }
  
    public pauseDynamicCampaign(campaign: Campaign): Promise<void> {
        const accountsStore = useAccountsStore();
        return httpService.put(`/dynamic-ads/${accountsStore.selectedAccount!.id}/campaigns/${this.identifier(campaign)}/pause`);
    } 

    public addLocation(campaign: Campaign, location: Location): Promise<void> {
        return httpService.post(this.entityEndpoint + '/' + this.identifier(campaign) + '/locations', {
            id: location.id,
            name: location.name,
            type: location.type,
            country: location.country,
        }).then(response => {
            const isLocationNotAllowedInSomeChannels = _.get(response, 'status') === 206;
            if (isLocationNotAllowedInSomeChannels) {
                toastNotificationStore.showToastNotification({
                    message: _.get(response, 'data.message'),
                    isError: true,
                });
            }
        });
    }

    public deleteLocation(campaign: Campaign, location: Location): Promise<void> {
        return httpService.delete(this.entityEndpoint + '/' + this.identifier(campaign) + '/locations', {
            data: {
                id: location.id,
                name: location.name,
                type: location.type,
                country: location.country,
            },
        });
    }

    public addProfile(campaign: Campaign, profile: JobProfile): Promise<void> {
        return httpService.post(this.entityEndpoint + '/' + this.identifier(campaign) + '/profiles', {
            id: profile.id,
        });
    }

    public deleteProfile(campaign: Campaign, profile: JobProfile): Promise<void> {
        return httpService.delete(this.entityEndpoint + '/' + this.identifier(campaign) + '/profiles/' + profile.id);
    }

    public deploy(campaign: Campaign): Promise<void> {
        return httpService.put(this.entityEndpoint + '/' + this.identifier(campaign) + '/deploy');
    }

  public extend(data: DataToExtendCampaign): Promise<void> {
        const url = this.entityEndpoint + '/' + this.identifier(data.campaign) + '/extend-with-channels';
        return httpService.put(url, { 
          additionalBudget: { amount: data.amount }, 
          previousEndDate: data.previousEndDate, 
          endDate: data.endDate,
          channels: {
            facebookStatus: data.facebookStatus,
            googleDisplayStatus: data.googleDisplayStatus,
            googleSearchStatus: data.googleSearchStatus,
            linkedinStatus: data.linkedinStatus,
            facebookAmount: data.facebookAmount,
            googleDisplayAmount: data.googleDisplayAmount,
            googleSearchAmount: data.googleSearchAmount,
            linkedinAmount: data.linkedinAmount,
          },
         });
    }
  
  // will be used anoher endpoint later
  public boost(campaign: Campaign, amount: number): Promise<void> {
    return httpService.put(this.entityEndpoint + '/' + this.identifier(campaign) + '/boost', { additionalBudget: { amount } });
  }

  public changeCampaignTypeToCustomWithAddedChannelBudget(data: { campaign: Campaign, channelName: string, channelAmount: number }): Promise<boolean> {
    const url = `${this.entityEndpoint}/${this.identifier(data.campaign)}/add-channel-budget`;
    return httpService.put(url, { channelName: data.channelName, channelAmount: data.channelAmount }).then(() => {
      toastNotificationStore.showToastNotification({
        message: 'Campaign type was changed to Custom',
        isSuccess: true,
      });
      return true;
    }).catch((error) => {
      toastNotificationStore.showToastNotification({
        message: error.response.data.message,
        isError: true,
      });
      return false;
    });
  }

    public changeCampaignType(campaign: Campaign, type: CampaignType): Promise<void> {
        const convertedType = convertTypeForDatabase(type);
        const url = this.entityEndpoint + '/' + this.identifier(campaign) + '/type';
        return httpService.put(url, { type: convertedType });
    }

    public setFunctionGroup(campaign: Campaign, functionGroup: FunctionGroup): Promise<void> {
        const url = this.entityEndpoint + '/' + this.identifier(campaign) + '/function-group';
        return httpService.put(url, { function_group: functionGroup ? functionGroup.toLowerCase() : null });
    }

    public removeFunctionGroup(campaign: Campaign): Promise<void> {
        const url = this.entityEndpoint + '/' + this.identifier(campaign) + '/function-group';
        return httpService.delete(url);
    }

    public setCampaignGoal(campaign: Campaign, goal: CampaignGoalValue): Promise<void> {
        const url = this.entityEndpoint + '/' + this.identifier(campaign) + '/goal';
        return httpService.put(url, { goal: goal ? goal : null });
    }

    public async selectSocialPage(id: string, socialPage: SocialPage): Promise<void> {
        const url = `${this.entityEndpoint}/${id}/social-page`;
        return httpService.put(url, { page_id: socialPage.id, channel: socialPage.channel });
    }

    public useCustomBudget(campaign: Campaign, totalBudget: number, channels: ChannelsBudget): Promise<void> {
        const url = this.entityEndpoint + '/' + this.identifier(campaign) + '/custom-budget';
        return httpService.put(url, {
            total_gross_budget_in_cents: totalBudget * 100,
            facebook_amount: channels.facebook * 100,
            google_display_amount: channels.googleDisplay * 100,
            google_search_amount: channels.googleSearch * 100,
            linkedin_amount: channels.linkedIn * 100,
        });
    }

    public fetchLeadform(campaignId: string): Promise<Leadform> {
        return httpService.get(`${this.entityEndpoint}/${campaignId}/leadform`).then((response) => {
            return this.hydrateLeadform(response.data as LeadformResponseJson);
        });
    }

    public saveLeadform({ leadform, campaignId }: { leadform: any, campaignId: string }): Promise<Leadform> {
        const payload = _.mapValues(leadformApiHydrationMap, (val) => leadform[val]);

        return httpService.put(`${this.entityEndpoint}/${campaignId}/leadform-draft`, payload).then((response) => {
            return this.hydrateLeadform(response.data as LeadformResponseJson);
        });
    }

    public validateLeadform(campaignId: string) {
        // @ts-ignore
        return httpService.get(`${this.entityEndpoint}/${campaignId}/leadform-status`, { suppressErrorToast: true });
    }

    // temporary solution to hide specific FB errors while BE is fixing the issue
    static filterOutCampaignError3858258(errors: { error_message: string }[]): any {
        return errors?.filter((e: any) => !e?.error_message?.includes('3858258')) ?? [];
    }

    public hydrate(json: CampaignResponseJson): Campaign {
        return {
            id: json.id,
            accountId: json.account_id,
            hash: json.hash,
            type: convertTypeForFrontend(json.type) as CampaignType,
            status: json.status as CampaignStatus,
            title: json.title,
            displayUrl: json.display_url,
            targetUrl: json.target_url,
            startDate: getDateWithTimezone(json.start_date, 'YYYY-MM-DD'),
            endDate: getDateWithTimezone(json.end_date, 'YYYY-MM-DD'),
            spend: json.spend ? Money.fromInteger(json.spend.amount, json.spend.currency)
                : Money.fromInteger(0, 'EUR'),
            locations: json.locations,
            profiles: json.profiles ? json.profiles.map((profile) => {
                return {
                    id: profile.id,
                    name: profile.name,
                    functionGroup: profile.function_group,
                };
            }) : [],
            instagramPageId: json.instagram_page_id,
            facebookPageId: json.facebook_page_id,
            linkedinPageId: json.linkedin_page_id,
            reach: json.reach,
            packageId: json.package_id,
            workspaceId: json.workspace_id,
            workspaceName: json.workspace_name,
            workspaceSlug: json.workspace_slug,
            usedTemplate: json.used_template,
            functionGroup: json.function_group ? this.capitalize(json.function_group) as FunctionGroup : '',
            goal: json.goal ? json.goal : '',
            googleBannerError: json.google_banner_error,
            poNumber: json.po_number,
            referenceNumber: json.reference_number,
            statistics: json.statistics,
            suggestedBudgetApplicable: json.suggested_budget_applicable,
            suggestedBudgetNonApplicabilityMessage: json.suggested_budget_non_applicability_message ?
                NON_APPLICABILITY_MESSAGES[json.suggested_budget_non_applicability_message] : undefined,
            suggestedTotalGrossBudget: json.suggested_total_gross_budget,
            customTotalGrossBudget: json.custom_total_gross_budget,
            customDailyBudget: json.custom_daily_budget,
            customChannelBudget: {
                facebook: Number(_.get(json.custom_channel_amounts, 'facebook', 0)),
                googleDisplay: Number(_.get(json.custom_channel_amounts, 'google_display', 0)),
                linkedIn: Number(_.get(json.custom_channel_amounts, 'linked_in', 0)),
                googleSearch: Number(_.get(json.custom_channel_amounts, 'google_search', 0)),
            },
            customChannelStatuses: json.custom_channel_statuses ? {
                facebook: json.custom_channel_statuses?.facebook,
                facebookFeed: json.custom_channel_statuses?.facebook_feed,
                instagramFeed: json.custom_channel_statuses?.instagram_feed,
                instagramStory: json.custom_channel_statuses?.instagram_story,
                googleDisplay: json.custom_channel_statuses?.google_display,
                googleSearch: json.custom_channel_statuses?.google_search,
                linkedIn: json.custom_channel_statuses?.linked_in,
            } : {
                facebook: { status: true },
                facebookFeed: { status: true },
                instagramFeed: { status: true },
                instagramStory: { status: true },
                googleDisplay: { status: true },
                googleSearch: { status: true },
                linkedIn: { status: true },
            },
            enabledChannels: json.enabled_channels ? {
                facebook: json.enabled_channels.facebook,
                instagram: json.enabled_channels.instagram,
                googleDisplay: json.enabled_channels.google_display,
                googleSearch: json.enabled_channels.google_search,
                linkedIn: json.enabled_channels.linked_in,
            } : {
                facebook: true,
                instagram: true,
                googleDisplay: true,
                linkedIn: true,
                googleSearch: true,
            },
            pausedAt: json.paused_at ? getDateWithTimezone(json.paused_at, 'YYYY-MM-DD') : undefined,
            language: json.language,
            facebookJobList: json.facebook_catalog,
            facebookJobListId: json.facebook_catalog_id,
            facebookPageLogo: json.facebookPageLogo,
            facebookPageName: json.facebookPageName,
            errors: json.errors ? CampaignResource.filterOutCampaignError3858258(json.errors).map((error: any) => ({
              code: error.code,
              createdAt: error.created_at,
              requestId: error.request_id,
              message: error.error_message,
              userMessage: error.user_message,
            })) : [],
            mediaErrors: json.media_errors,
            facebook_cap_strategy: json.facebook_cap_strategy,
        };
    }

    public hydrateLeadform(json: LeadformResponseJson): Leadform {
        const mappedKeysObj = _.mapKeys(json, (val, key) => leadformApiHydrationMap[key]);
        const mappedValuesObj = _.mapValues(mappedKeysObj, (val: string | AdMediaJson, key: string) => {
            if (key === 'media') {
                return val ? hydrateAdMedia(val as AdMediaJson) : null;
            } else if (key === 'questions') {
                return val === null ? [] : val;
            } else {
                return val === null ? '' : val;
            }
        });

        return mappedValuesObj as any;
    }

    protected extract(model: Campaign): CampaignRequestJson {
        return {
            id: model.id,
            status: model.status,
            type: model.type,
        };
    }

    private extractCampaignFields(campaign: Campaign): CampaignFieldsJson {
        return {
            title: campaign.title ? campaign.title : undefined,
            target_url: campaign.targetUrl ? campaign.targetUrl : undefined,
            po_number: campaign.poNumber ? campaign.poNumber : undefined,
            reference_number: campaign.referenceNumber ? campaign.referenceNumber : undefined,
            language: campaign.language ? campaign.language : undefined,
        };
    }

    private capitalize(name: string): string {
        if (name === 'it') {
            return 'IT';
        }

        if (name === 'charity and fundraising') {
            return 'Charity and Fundraising';
        }

        return name.replace(/(?:^|\s|\/)\S/g, (a) => a.toUpperCase());
    }
}

const NON_APPLICABILITY_MESSAGES: Record<string, string> = {
    NO_TARGETING_SPECIFIED: 'Cannot suggest a budget when no targeting is specified',
    ESTIMATED_REACH_UNKNOWN: 'Cannot suggest a budget when estimated reach is unknown.',
    NO_PROFILE_SELECTED: 'Cannot suggest a budget when no profile is selected.',
    NO_LOCATION_SELECTED: 'Cannot suggest a budget when no location is selected.',
    NO_UNIFORM_AUDIENCE: 'Cannot suggest a budget when audience is not uniform',
    CUSTOM_CAMPAIGN: 'Cannot suggest a budget when campaign type is custom',
    UNKNOWN_CPM: 'Cannot suggest a budget. We don\'t have enough data.',
};

const leadformApiHydrationMap: Record<string, string> = {
    name: 'name',
    intro_headline: 'introHeadline',
    intro_description: 'introDescription',
    privacy_url: 'privacyUrl',
    privacy_title: 'privacyTitle',
    completion_headline: 'completionHeadline',
    completion_description: 'completionDescription',
    completion_button_text: 'completionButtonText',
    completion_button_url: 'completionButtonUrl',
    questions_description: 'questionsDescription',
    questions: 'questions',
    media: 'media',
};

export interface ActivityLogResponseJson {
    date: string;
    user: string;
    message: string;
}

export interface CampaignResponseJson {
    id: string;
    account_id: string;
    hash: string;
    status: string;
    type: string;
    title?: string;
    display_url?: string;
    target_url?: string;
    start_date: string;
    end_date: string;
    reach: number;
    package_id?: string;
    workspace_id?: string;
    workspace_name?: string;
    workspace_slug?: string;
    used_template?: boolean;
    spend?: MoneyJson;
    locations: Location[];
    profiles: JobProfileJson[];
    function_group?: FunctionGroup;
    goal?: CampaignGoalValue;
    google_banner_error: null | string;
    po_number?: string;
    reference_number?: string;
    facebook_page_id: string | null;
    instagram_page_id: string | null;
    facebookPageLogo?: string | null;
    facebookPageName?: string | null;
    linkedin_page_id: string | null;
    statistics: Statistics | null;
    suggested_budget_applicable: boolean;
    suggested_budget_non_applicability_message?: string;
    suggested_total_gross_budget?: number;
    custom_total_gross_budget?: number;
    custom_daily_budget?: number;
    custom_channel_percentages?: ChannelBudgetJson;
    custom_channel_amounts?: ChannelBudgetJson;
    custom_channel_statuses?: CustomChannelStatusesJson;
    enabled_channels: {
        facebook: boolean;
        instagram: boolean;
        google_display: boolean;
        linked_in: boolean;
        google_search: boolean;
    };
    errors?: ErrorItemJson[];
    media_errors?: string[];
    paused_at?: string;
    language?: string;
    facebook_catalog?: FacebookJobListData;
    facebook_catalog_id?: string;
    facebook_cap_strategy?: FacebookCapStrategy;
}

interface CampaignRequestJson {
    id: string;
    status: string;
    type: string;
}

interface LeadformResponseJson {
    name: string | null;
    intro_headline: string | null;
    intro_description: string | null;
    privacy_url: string | null;
    privacy_title: string | null;
    completion_headline: string;
    completion_description: string;
    completion_button_text: string;
    completion_button_url: string | null;
    questions_description: string | null;
    questions: [LeadformQuestion];
    media: AdMediaJson | null;
}

interface CustomChannelStatusesJson {
  facebook: { status: boolean };
  facebook_feed: { status: boolean };
  instagram_feed: { status: boolean };
  instagram_story: { status: boolean };
  google_display: { status: boolean };
  google_search: { status: boolean };
  linked_in: { status: boolean };
}

interface ChannelBudgetJson {
    facebook: number;
    google_display: number;
    linked_in: number;
    google_search: number;
}

interface MoneyJson {
    amount: number;
    currency: string;
}

interface DuplicateResponseJson {
    campaign_id: string;
}

interface CampaignFieldsJson {
    title?: string;
    target_url?: string;
    po_number?: string;
    reference_number?: string;
    language?: string;
}

interface JobProfileJson {
    id: string;
    name: string;
    function_group: string;
}

interface ErrorItemJson {
  code: number;
  created_at: string;
  request_id: string;
  error_message: string;
  user_message: string;
}
