import _ from 'lodash';
import {AbstractResource} from '@/core/api/abstract.api';
import campaignStore from '../../campaigns/store/campaigns.store';
import {FacebookPostAd} from '../models/facebookAds.model';
import {MediaType} from '@/modules/medialibrary/models/masterImage.model';
import { ErrorItemJson } from '../models/ads.model';
import { hydrateAdMedia, AdMediaJson } from '@/modules/ads/api/apiHelpers';
import CampaignResource from '@/modules/campaigns/api/campaign.api';

export default class FacebookPostAdResource extends AbstractResource<
  FacebookPostAd,
  FacebookPostAdResponseJson,
  FacebookPostAdRequestJson
  > {
    protected get resourceName() {
        return 'facebook-post-ads';
    }

    protected get resourceOwner(): string {
        if (!campaignStore.selectedCampaign) {
            throw new Error('Trying to perform ad related actions without a selected campaign');
        }
        return `campaigns/${campaignStore.selectedCampaign.id}`;
    }

    protected extract(model: FacebookPostAd): FacebookPostAdRequestJson {
        return {
            media: {
                id: model.media.id,
                type: model.media.type,
            },
            message: model.message,
            headline: model.headline,
            link_description: model.linkDescription,
            call_to_action: model.callToAction,
        };
    }

    protected hydrate(json: FacebookPostAdResponseJson): FacebookPostAd {
        const error = json.error ? CampaignResource.filterOutCampaignError3858258([json.error])[0] : null;
        return {
            id: String(json.id),
            status: 'active',
            media: hydrateAdMedia(json.media),
            mediaErrors: json.media_errors,
            message: json.message,
            error: error ? {
              code: error.code,
              createdAt: error.created_at,
              errorMessage: error.error_message,
              userMessage: error.user_message,
            } : null,
            headline: json.headline,
            displayUrl: _.get(campaignStore, 'selectedCampaign.displayUrl'),
            linkDescription: json.link_description,
            callToAction: json.call_to_action,
        };
    }
}

export interface FacebookPostAdResponseJson {
  id: string;
  media: AdMediaJson;
  media_errors?: string[];
  message: string;
  error: ErrorItemJson | null;
  headline: string;
  link_description: string;
  call_to_action: string;
}

export interface FacebookPostAdRequestJson {
    media: {
        id: string;
        type: MediaType;
    };
    message: string;
    headline: string;
    link_description: string;
    call_to_action: string;
}
