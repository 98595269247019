import _ from 'lodash';
import {AbstractResource} from '@/core/api/abstract.api';
import campaignStore from '../../campaigns/store/campaigns.store';
import {FacebookCarouselAd} from '../models/facebookAds.model';
import {MediaType} from '@/modules/medialibrary/models/masterImage.model';
import { ErrorItemJson } from '../models/ads.model';
import { hydrateAdMedia, AdMediaJson } from '@/modules/ads/api/apiHelpers';
import CampaignResource from '@/modules/campaigns/api/campaign.api';

export default class FacebookCarouselAdResource extends AbstractResource<
  FacebookCarouselAd,
  FacebookCarouselAdResponseJson,
  FacebookCarouselAdRequestJson
  > {
    protected get resourceName() {
       return 'facebook-carousel-ads';
    }

    protected get resourceOwner(): string {
        if (!campaignStore.selectedCampaign) {
            throw new Error('Trying to perform ad related actions without a selected campaign');
        }
        return `campaigns/${campaignStore.selectedCampaign.id}`;
    }

    protected extract(model: FacebookCarouselAd): FacebookCarouselAdRequestJson {
        return {
            media: model.media.map((mediaItem) => {
                return {
                    id: mediaItem.id,
                    type: mediaItem.type,
                };
            }),
            message: model.message,
            headline: model.headline,
            link_description: model.linkDescription,
            call_to_action: model.callToAction,
        };
    }

    protected hydrate(json: FacebookCarouselAdResponseJson): FacebookCarouselAd {
      const error = json.error ? CampaignResource.filterOutCampaignError3858258([json.error])[0] : null;
      return {
        id: String(json.id),
        status: 'active',
        media: json.media.map((media) => hydrateAdMedia(media)),
        mediaErrors: json.media_errors,
        message: json.message,
        error: error ? {
          code: error.code,
          createdAt: error.created_at,
          errorMessage: error.error_message,
          userMessage: error.user_message,
        } : null,
        headline: json.headline,
        displayUrl: _.get(campaignStore, 'selectedCampaign.displayUrl'),
        linkDescription: json.link_description,
        callToAction: json.call_to_action,
      };
    }
}

export interface FacebookCarouselAdResponseJson {
  id: string;
  media: AdMediaJson[];
  media_errors?: string[];
  message: string;
  error: ErrorItemJson | null;
  headline: string;
  link_description: string;
  call_to_action: string;
}

export interface FacebookCarouselAdRequestJson {
    media: Array<{id: string, type: MediaType}>;
    message: string;
    headline: string;
    link_description: string;
    call_to_action: string;
}
