import _ from 'lodash';
import httpService from '@/core/plugins/httpService';
import { AbstractResource } from '@/core/api/abstract.api';

import { useAccountsStore } from '@/modules/accounts/store/accounts.store';
import { useWorkspaceStore } from '@/modules/accounts/store/workspaces.store';

import { Campaign, CampaignStatus, CampaignType, Statistics } from '@/modules/campaigns/models/campaign.model';
import { CampaignGoalValue } from '@/modules/campaigns/models/campaign.goal.model';
import { FunctionGroup } from '@/modules/campaigns/models/function.group.model';
import { getDateWithTimezone } from '@/core/plugins/date';
import { Location } from '@/core/models/location.model';
import { Money } from 'ts-money';
import CampaignResource from '@/modules/campaigns/api/campaign.api';

export default class ErroredCampaignResource extends AbstractResource<Campaign, ErroredCampaignResponseJson, ErroredCampaignRequestJson> {
  
public findAllErroredCampaigns(pageCount: number): Promise<Campaign[]> {
    const accountsStore = useAccountsStore();
    return httpService.get(`/accounts/${accountsStore.selectedAccount!.id}/${this.resourceName}?page=${pageCount}&perpage=10`).then((response) => {
      const rawErroredCampaigns: ErroredCampaignResponseJson[] = response.data.data;
      return this.hydrateCollection(rawErroredCampaigns).filter(i => i?.errors?.length);
    });
}

public async retryDeployOneAd(campaignId: string, requestId: string): Promise<void> {
  const accountsStore = useAccountsStore();
  return await httpService.put(`/accounts/${accountsStore.selectedAccount!.id}/${this.resourceName}/${campaignId}/${requestId}/retry`);
}
  
public async resolveOneAd(campaignId: string, requestId: string, resolution: string, note: string): Promise<void> {
  const accountsStore = useAccountsStore();
  return await httpService.put(`/accounts/${accountsStore.selectedAccount!.id}/${this.resourceName}/${campaignId}/${requestId}/resolve`,
  {
    resolution,
    note,
  });
}
  
public async redeploy(campaignId: string): Promise<void> {
  return await httpService.put(`/campaigns/${campaignId}/redeploy`);
}

protected extract(model: Campaign): ErroredCampaignRequestJson {
    return {
        id: model.id,
        status: model.status,
    };
}

protected hydrate(json: ErroredCampaignResponseJson): Campaign {
    const erroredCampaign: Campaign = {
    id: json.id,
    accountId: json.account_id,
    hash: json.hash,
    status: json.status as CampaignStatus,
    type: json.type as CampaignType,
    title: json.title,
    displayUrl: json.display_url,
    targetUrl: json.target_url,
    startDate: getDateWithTimezone(json.start_date, 'YYYY-MM-DD'),
    endDate: getDateWithTimezone(json.end_date, 'YYYY-MM-DD'),
    reach: json.reach,
    workspaceId: json.workspace_id,
    workspaceName: json.workspace_name,
    workspaceSlug: json.workspace_slug, 
    spend:  json.spend ? Money.fromInteger(json.spend.amount, json.spend.currency)
    : Money.fromInteger(4100, 'EUR'),
    locations: json.locations,
    profiles: json.profiles ? json.profiles.map((profile) => {
      return {
          id: profile.id,
          name: profile.name,
          functionGroup: profile.function_group,
      };
  }) : [],
    functionGroup: json.function_group,
    goal: json.goal,
    googleBannerError: json.google_banner_error,
    poNumber: json.po_number,
    referenceNumber: json.reference_number,
    statistics: json.statistics,
    facebookPageId: json.facebook_page_id,
    instagramPageId: json.instagram_page_id,
    linkedinPageId: json.linkedin_page_id,
    suggestedBudgetApplicable: json.suggested_budget_applicable,
    suggestedBudgetNonApplicabilityMessage: json.suggested_budget_non_applicability_message ?
          NON_APPLICABILITY_MESSAGES[json.suggested_budget_non_applicability_message] : undefined,
    suggestedTotalGrossBudget: json.suggested_total_gross_budget,
    customTotalGrossBudget: json.custom_total_gross_budget,
    customChannelBudget: {
        facebook: Number(_.get(json.custom_channel_amounts, 'facebook', 0)),
        googleDisplay: Number(_.get(json.custom_channel_amounts, 'google_display', 0)),
        linkedIn: Number(_.get(json.custom_channel_amounts, 'linked_in', 0)),
        googleSearch: Number(_.get(json.custom_channel_amounts, 'google_search', 0)),
    },
    enabledChannels: json.enabled_channels ? {
        facebook: json.enabled_channels.facebook,
        instagram: json.enabled_channels.instagram,
        googleDisplay: false,
        googleSearch: false,
        linkedIn: false,
    } : {
        facebook: true,
        instagram: true,
        googleDisplay: false,
        googleSearch: false,
        linkedIn: false,
    },
    errors: json.errors ? CampaignResource.filterOutCampaignError3858258(json.errors).map((error: any) => {
      return {
        code: error.code,
        createdAt: error.created_at,
        requestId: error.request_id,
        message: error.error_message,
        userMessage: error.user_message,
      };
    }) : [],
    pausedAt: json.paused_at ? getDateWithTimezone(json.paused_at, 'YYYY-MM-DD') : undefined,
    language: json.language,
  };
  return erroredCampaign;
}

protected get resourceName() {
    return 'errored-campaigns';
}

protected get resourceOwner() {
    const accountsStore = useAccountsStore();
    const workspaceStore = useWorkspaceStore();
    
    return 'accounts/' + accountsStore.selectedAccount!.id + '/workspaces/' + workspaceStore.selectedWorkspace!.slug;
}  
} 

const NON_APPLICABILITY_MESSAGES: Record<string, string> = {
  NO_TARGETING_SPECIFIED: 'Cannot suggest a budget when no targeting is specified',
  ESTIMATED_REACH_UNKNOWN: 'Cannot suggest a budget when estimated reach is unknown.',
  NO_PROFILE_SELECTED: 'Cannot suggest a budget when no profile is selected.',
  NO_LOCATION_SELECTED: 'Cannot suggest a budget when no location is selected.',
  NO_UNIFORM_AUDIENCE: 'Cannot suggest a budget when audience is not uniform',
  CUSTOM_CAMPAIGN: 'Cannot suggest a budget when campaign type is custom',
  UNKNOWN_CPM: 'Cannot suggest a budget. We don\'t have enough data.',
};
export interface ErroredCampaignRequestJson {
  id: string;
  status: string;
}
export interface ErroredCampaignResponseJson {
  id: string;
  account_id: string;
  hash: string;
  status: string;
  type: string;
  title?: string;
  display_url?: string;
  target_url?: string;
  start_date: string;
  end_date: string;
  reach: number;
  workspace_id?: string;
  workspace_name: string;
  workspace_slug: string;
  spend?: MoneyJson;
  locations: Location[];
  profiles: JobProfileJson[];
  function_group?: FunctionGroup;
  goal?: CampaignGoalValue;
  google_banner_error: null | string;
  po_number?: string;
  reference_number?: string;
  facebook_page_id: string | null;
  instagram_page_id: string | null;
  linkedin_page_id: string | null;
  statistics: Statistics | null;
  suggested_budget_applicable: boolean;
  suggested_budget_non_applicability_message?: string;
  suggested_total_gross_budget?: number;
  custom_total_gross_budget?: number;
  custom_channel_percentages?: ChannelBudgetJson;
  custom_channel_amounts?: ChannelBudgetJson;
  enabled_channels: {
      facebook: boolean;
      instagram: boolean;
      google_display: boolean;
      linked_in: boolean;
  };
  errors?: ErrorItemJson[];
  paused_at?: string;
  language?: string;
}
interface ChannelBudgetJson {
  facebook: number;
  google_display: number;
  linked_in: number;
  google_search: number;
}

interface JobProfileJson {
  id: string;
  name: string;
  function_group: string;
}
interface MoneyJson {
  amount: number;
  currency: string;
}
interface ErrorItemJson {
  code: number;
  created_at: string;
  request_id: string;
  error_message: string;
  user_message: string;
}
