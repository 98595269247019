import { AbstractResource } from '@/core/api/abstract.api';
import campaignStore from '../../campaigns/store/campaigns.store';
import { InstagramCarouselAd} from '../models/instagramAds.model';
import { MediaType } from '@/modules/medialibrary/models/masterImage.model';
import { ErrorItemJson } from '../models/ads.model';
import { hydrateAdMedia, AdMediaJson } from '@/modules/ads/api/apiHelpers';
import CampaignResource from '@/modules/campaigns/api/campaign.api';

export default class InstagramCarouselAdResource extends AbstractResource<
  InstagramCarouselAd,
  InstagramCarouselAdResponseJson,
  InstagramCarouselAdRequestJson
  > {

    protected get resourceName() {
        return 'instagram-carousel-ads';
    }

    protected get resourceOwner(): string {
        if (!campaignStore.selectedCampaign) {
            throw new Error('Trying to perform ad related actions without a selected campaign');
        }
        return `campaigns/${campaignStore.selectedCampaign.id}`;
    }

    protected extract(model: InstagramCarouselAd): InstagramCarouselAdRequestJson {
        return {
            media: model.media.map((mediaItem) => {
                return {
                    id: mediaItem.id,
                    type: mediaItem.type,
                };
            }),
            message: model.message,
            call_to_action: model.callToAction,
        };
    }

    protected hydrate(json: InstagramCarouselAdResponseJson): InstagramCarouselAd {
      const error = json.error ? CampaignResource.filterOutCampaignError3858258([json.error])[0] : null;
      return {
        id: String(json.id),
        status: 'active',
        media: json.media.map((media) => hydrateAdMedia(media)),
        mediaErrors: json.media_errors,
        message: json.message,
        error: error ? {
          code: error.code,
          createdAt: error.created_at,
          errorMessage: error.error_message,
          userMessage: error.user_message,
        } : null,
        callToAction: json.call_to_action,
      };
    }
}

export interface InstagramCarouselAdResponseJson {
  id: string;
  media: AdMediaJson[];
  media_errors?: string[];
  call_to_action: string;
  message: string;
  error: ErrorItemJson | null;
}

export interface InstagramCarouselAdRequestJson {
    media: Array<{id: string, type: MediaType}>;
    call_to_action: string;
    message: string;
}
