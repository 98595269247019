import { render, staticRenderFns } from "./CampaignForm.vue?vue&type=template&id=5b2bce32&scoped=true&"
import script from "./CampaignForm.vue?vue&type=script&setup=true&lang=ts&"
export * from "./CampaignForm.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./CampaignForm.vue?vue&type=style&index=0&id=5b2bce32&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b2bce32",
  null
  
)

export default component.exports